import { debounce } from './functions';

class Badges {
  constructor(badges) {
    this.badges = badges;
    this.init();
  }

  init() {
    this.adjustBadgeTitleHeadline();

    $(window).on(
      'resize',
      debounce(() => {
        this.adjustBadgeTitleHeadline();
      }, 250),
    );
  }

  /*
   * checks the width/height of the badgetitle, for a wordwrap in the title and then adjust the fontsize/line-hight
   */
  adjustBadgeTitleHeadline() {
    const circleWidth = this.badges
      .find('.lnk-badges__element > svg circle')[0]
      .getBoundingClientRect().width;
    const circleHeight = this.badges
      .find('.lnk-badges__element > svg circle')[0]
      .getBoundingClientRect().height;
    const circlePadding = 35;
    const circlePaddingTop = (85 * circleHeight) / 136;
    this.badges.find('.lnk-badges__element').each(function () {
      $(this)
        .children('.lnk-badges__element-title')
        .html(
          $(this)
            .children('.lnk-badges__element-title')
            .html()
            .replace(/ {2}/g, '<br>'),
        );

      let whileEnd = false;
      let fontSize = 30;
      while (!whileEnd && fontSize > 8) {
        $(this)
          .children('.lnk-badges__element-title')
          .css({ 'font-size': fontSize + 'px' });
        $(this)
          .children('.lnk-badges__element-title')
          .css('line-height', fontSize * 1.1 + 'px');
        let elementWidth = $(this)
          .children('.lnk-badges__element-title')
          .width();
        let elementHeight = $(this)
          .children('.lnk-badges__element-title')
          .height();
        if (
          circleWidth - circlePadding >= elementWidth &&
          circleHeight - circlePaddingTop >= elementHeight
        ) {
          whileEnd = true;
        } else {
          fontSize--;
        }
      }
      $(this)
        .children('.lnk-badges__element-title')
        .css('visibility', 'visible');
    });
  }
}

export default Badges;
