import { debounce } from './functions';

class Animation {
  constructor() {
    $(
      '.lnk-component:not(.lnk-puzzle-element), .lnk-section__headline, .lnk-section__subheadline',
    ).each(function () {
      let bottomWindow = $(window).scrollTop() + $(window).height();
      let topComponent = $(this).offset().top;

      if (bottomWindow >= topComponent) {
        $(this).addClass('js-opacity-1');
      } else {
        $(this).addClass('js-opacity-0');
      }
    });

    $(window).on(
      'scroll',
      debounce(() => {
        $('.js-opacity-0').each(function () {
          let bottomWindow = $(window).scrollTop() + $(window).height();
          let topComponent = $(this).offset().top;

          if (bottomWindow - 50 >= topComponent) {
            $(this).removeClass('js-opacity-0');
            $(this).addClass('js-opacity-1');
          }
        });
      }, 5),
    );
  }
}

export default Animation;
