import carousel from './custom/carousel';
import Lightbox from './custom/lightbox';

$(document).ready(function () {
  $('.lnk-carousel').each(function () {
    new carousel($(this));
  });

  new Lightbox();
});
