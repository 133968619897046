let debounce = function (func, wait, immediate) {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;

    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};

/**
 * returns current viewport
 */
function returnViewport() {
  let envs = ['xs', 'sm', 'md', 'lg', 'xl'];
  let viewports = [...envs];

  let el = document.createElement('div');
  document.body.appendChild(el);

  let curEnv = envs.shift();

  for (let env of envs.reverse()) {
    el.classList.add(`d-${env}-none`);

    if (window.getComputedStyle(el).display === 'none') {
      curEnv = env;
      break;
    }
  }

  document.body.removeChild(el);
  return {
    currentViewport: curEnv,
    viewports: viewports,
  };
}

/**
 * !!!!
 *
 */
function getURLParam(key) {
  let queryString = window.location.search;

  if (queryString === '') {
    return null;
  } else {
    let keyValuePairs = {};

    queryString = queryString.substr(1);

    // eslint-disable-next-line no-unused-vars
    queryString.split('&').forEach((element) => {
      let indexOfEqualsign = queryString.indexOf('=');
      let stringKey = queryString.substr(0, indexOfEqualsign);
      let stringValue = queryString.substr(indexOfEqualsign + 1);
      keyValuePairs[stringKey] = stringValue;
    });
    // eslint-disable-next-line no-prototype-builtins
    if (!keyValuePairs.hasOwnProperty(key)) {
      return null;
    } else {
      return keyValuePairs[key];
    }
  }
}

/**
 * Returns the current context path with a slash at the end.
 * If the current context is the ROOT context, only the slash
 * is returned.
 * @returns the context path
 */
function getContextPath() {
  let contextpath = $('#lnk-context').attr('data-ctx-contextpath');
  if (contextpath === undefined) {
    return '/';
  } else {
    return contextpath !== '/' ? (contextpath += '/') : contextpath;
  }
}

/**
 * System state helper method. Check for edit mode and for author instance.
 * @returns
 * 	- isEditMode: true if edit mode, otherwise false
 * 	- isAuthorInstance: true if author instance, otherwise false
 */
function getSystemState() {
  let editmode = $('#lnk-context').attr('data-ctx-editmode');
  let authorinstance = $('#lnk-context').attr('data-ctx-authorinstance');

  return {
    isEditMode: editmode === 'true' ? true : false,
    isAuthorInstance: authorinstance === 'true' ? true : false,
  };
}

export {
  debounce,
  returnViewport,
  getURLParam,
  getContextPath,
  getSystemState,
};
