/**
 * Loading Spinner must be imported to the using JS file
 * There is no default import to app.js
 */
class LoadingSpinner {
  constructor(classesString = '') {
    this.classesString = classesString;
  }

  /**
   * This loading spinner is replaceable if a client wants to use an other spinner
   * Possible loading spinner can be found here https://loading.io/css/
   * If you want to change the spinner you can replace the markup - but you must take "classesString" and .lnk-loading-spinner to the new markup
   * If you want to change the spinner don't forget to adjust the stylings in _loader.scss
   * @param classesString - string of injectable classes
   * @returns Loading Spinner as Template String
   */
  template() {
    return `
<div class="lnk-loading-spinner lds-roller d-block ${this.classesString}">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>`;
  }
}

export default LoadingSpinner;
