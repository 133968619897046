import {
  returnViewport,
  debounce,
} from '../../../../basic-templating-kit/webresources-src/js/custom/functions';

class Carousel {
  constructor(mainCarousel) {
    let isResponsiveCarousel = mainCarousel.hasClass(
      'lnk-carousel--responsive',
    );

    if (isResponsiveCarousel) {
      this.currentSlidePositionIndex = 0; // number of slides (click events next/prev)
      this.currentSlidePosition = 0; // absolute position after one slide
      this.responsiveInit(mainCarousel);
    }
  }

  responsiveInit(mainCarousel) {
    let that = this;

    that.responsiveHandling(mainCarousel);
    /** event: resize, to determine the viewport + function call: responsiveHandling() */
    $(window).on(
      'resize',
      debounce(function () {
        that.responsiveHandling(mainCarousel);
      }, 300),
    );
  }

  responsiveHandling(mainCarousel) {
    // let that = this;
    let viewports = returnViewport().viewports;
    let currentViewport = returnViewport().currentViewport;
    // let elementsPerSlide = 1;
    // let allCarouselElements = mainCarousel.find('.carousel-item > *');
    // let numberOfElements = allCarouselElements.length;
    // let numberOfSlidingElementsPerClick = 1;
    // let elementsWidth = 0;
    // let elementsWidthAbsolute = 0; // elementsWidth in px

    /**
     *  every defined viewport has his own slide number
     *  if the viewport not defined the viewport becomes the next lower viewport
     */
    if (mainCarousel.data(currentViewport)) {
      /**
       * viewport is defined in the carousel
       */
      // elementsPerSlide = mainCarousel.data(currentViewport);
    } else {
      /**
       *  the viewport becomes the value of the next lower viewport
       */
      for (let i = viewports.indexOf(currentViewport); i > -1; i--) {
        if (mainCarousel.data(viewports[i])) {
          // elementsPerSlide = mainCarousel.data(viewports[i]);
          break;
        }
      }
    }

    // elementsWidth = (1 / elementsPerSlide) * 100;

    // elementsWidthAbsolute = parseInt(
    //   mainCarousel.find('.carousel-item > *').first().width(),
    // );
    // let paddingBottomFirstElement = parseInt(
    //   mainCarousel
    //     .find('.carousel-item > * .lnk-teaser__image')
    //     .first()
    //     .css('padding-bottom'),
    // );

    mainCarousel.find('.carousel-item > * .lnk-teaser__image').css({
      height:
        mainCarousel.find('.carousel-item > * .lnk-teaser__image').width() +
        'px',
    });

    // Arrow prev/next calculation

    let textHeight = mainCarousel.find(
      '.carousel-item > * .lnk-teaser__text',
    )[0].clientHeight;

    mainCarousel.find('.carousel-control-prev').css({
      bottom: textHeight + 'px',
    });
    mainCarousel.find('.carousel-control-next').css({
      bottom: textHeight + 'px',
    });
  }
}

export default Carousel;
