class Table {
  constructor(table) {
    this.table = table;
    this.init();
  }

  init() {
    this.table.wrap('<div class="lnk-table"></div>');
  }
}

export default Table;
