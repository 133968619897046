import {
  getSystemState,
  returnViewport,
  debounce,
} from '../../../../basic-templating-kit/webresources-src/js/custom/functions';

import { generateNotification } from './notifications';

class Hotspot {
  constructor(hotspotComponent) {
    this.urlPost = $('.lnk-hotspot').attr('data-resturl-post');
    this.draggedPin = null;
    this.renderedMap = $(hotspotComponent).find('.lnk-hotspot__map');

    this.hotspotHandlingViewports = ['xl', 'lg'];
    this.currentViewport = returnViewport().currentViewport;
    this.mapDefaultWidth = hotspotComponent.find('.lnk-hotspot__map').width();

    // check for image loaded
    const interval = setInterval(() => {
      if (this.renderedMap.height() > 0) {
        this.init(hotspotComponent);
        clearInterval(interval);
      }
    }, 50);
  }

  init(hotspotComponent) {
    if (!getSystemState().isEditMode) {
      this.observeClickOnHotspotElements(hotspotComponent);
      if (!this.hotspotHandlingViewports.includes(this.currentViewport)) {
        this.setUpAccordion(hotspotComponent);
      }
    } else {
      this.enableDragAndDropInEditMode(hotspotComponent);
    }
    this.responsiveHandling(hotspotComponent);

    $(window).on(
      'resize',
      debounce(() => {
        this.currentViewport = returnViewport().currentViewport;
        if (!this.hotspotHandlingViewports.includes(this.currentViewport)) {
          this.setUpAccordion(hotspotComponent);
        }
        this.responsiveHandling(hotspotComponent);
      }, 300),
    );
  }

  responsiveHandling(hotspotComponent) {
    let targetWidth;
    let wrapperOffset;
    let scale;

    if (this.hotspotHandlingViewports.includes(this.currentViewport)) {
      // Desktop
      targetWidth = $(hotspotComponent).width() / 2;
      wrapperOffset =
        $(hotspotComponent).find('.lnk-hotspot__box').width() / 12;
      scale = targetWidth / this.mapDefaultWidth;
    } else {
      // Mobile
      targetWidth = $(hotspotComponent).width();
      wrapperOffset = 0;
      scale = targetWidth / this.mapDefaultWidth;
    }

    $(hotspotComponent)
      .find('.lnk-hotspot__map-wrapper')
      .css({
        transform: 'scale(' + scale + ')',
        'margin-right': wrapperOffset + 'px',
      });
    $(hotspotComponent)
      .find('.lnk-hotspot__text-container')
      .css('padding-right', wrapperOffset * 2 + 'px');

    if (this.hotspotHandlingViewports.includes(this.currentViewport)) {
      this.setComponentHeightDesktop(hotspotComponent, scale);
    } else {
      this.setComponentHeightMobile(hotspotComponent);
    }
  }

  setComponentHeightDesktop(hotspotComponent, scale) {
    const map = $(hotspotComponent).find('.lnk-hotspot__map-wrapper');
    const mapHeight = map.height() * scale;
    const mapTop = map.css('top');
    const componentHeight = `calc(${mapHeight}px + ${mapTop})`.toString();

    $(hotspotComponent).find('.lnk-hotspot__box').css('height', 'auto');
    $(hotspotComponent).css('min-height', componentHeight);
  }

  setComponentHeightMobile(hotspotComponent) {
    const textBox = $(hotspotComponent).find('.lnk-hotspot__text-container');
    const textBoxCurrentHeight = textBox.outerHeight();
    const mapCurrentHeight = $(hotspotComponent)
      .find('.lnk-hotspot__map-container img')[0]
      .getBoundingClientRect().height;

    const mapTargetHeight = mapCurrentHeight + textBoxCurrentHeight;

    $(hotspotComponent).css('min-height', '0');
    $(hotspotComponent)
      .find('.lnk-hotspot__box')
      .css('height', `${mapTargetHeight}px`);
  }

  observeClickOnHotspotElements(hotspotComponent) {
    $(hotspotComponent)
      .find('.lnk-hotspot__pin')
      .on('click', (event) => {
        if (this.hotspotHandlingViewports.includes(this.currentViewport)) {
          const isClickedPinActive = $(event.target)
            .closest('.lnk-hotspot__pin')
            .find('.lnk-hotspot__pin-icon--inactive')
            .hasClass('d-none');

          if (isClickedPinActive) {
            this.resetAllPins(hotspotComponent);
          } else {
            this.activateClickedPin(event, hotspotComponent);
          }
        }
      });
  }

  resetAllPins(hotspotComponent) {
    $(hotspotComponent)
      .find('.lnk-hotspot__box > .lnk-hotspot__elements .lnk-hotspot-element')
      .each(function () {
        $(this).addClass('d-none');
      });

    $(hotspotComponent).find('.lnk-hotspot__elements').addClass('d-none');

    this.resetHotspotIcons(hotspotComponent);

    $(hotspotComponent)
      .find('.lnk-hotspot__text-container')
      .removeClass('d-none');
  }

  activateClickedPin(event, hotspotComponent) {
    $(hotspotComponent).find('.lnk-hotspot__text-container').addClass('d-none');
    $(hotspotComponent).find('.lnk-hotspot__elements').removeClass('d-none');

    // Reset all non accordion element (only 1 element should be active)
    $(hotspotComponent)
      .find('.lnk-hotspot__box > .lnk-hotspot__elements .lnk-hotspot-element')
      .each(function () {
        $(this).addClass('d-none');
      });

    // Reset Active icons
    this.resetHotspotIcons(hotspotComponent);

    const clickedPinNode = $(event.target)
      .closest('.lnk-hotspot__pin')
      .attr('data-pin-node');

    // Activate active icon of clicked pin
    $(event.target)
      .closest('.lnk-hotspot__pin')
      .find('.lnk-hotspot__pin-icon--active')
      .removeClass('d-none');
    $(event.target)
      .closest('.lnk-hotspot__pin')
      .find('.lnk-hotspot__pin-icon--inactive')
      .addClass('d-none');

    $(
      '.lnk-hotspot-element[data-pin-node="' + clickedPinNode + '"]',
    ).removeClass('d-none');

    $(hotspotComponent).find('.lnk-hotspot__text-container').addClass('d-none');
  }

  setUpAccordion(hotspotComponent) {
    // dont display non accordion hotspot elements
    $(hotspotComponent)
      .find('.lnk-hotspot__box > .lnk-hotspot__elements')
      .addClass('d-none');
    // display initial hotspot information
    $(hotspotComponent)
      .find('.lnk-hotspot__text-container')
      .removeClass('d-none');
    // Initialize hotspot icons
    this.resetHotspotIcons(hotspotComponent);

    // pin of accordion element will be active after open
    $(hotspotComponent)
      .find('.lnk-accordion__element-head--hotspot')
      .on('click', (event) => {
        const pinID = $(event.target).attr('aria-controls');
        const inactivePin = $(hotspotComponent).find(
          '.lnk-hotspot__pin[data-pin-id="' +
            pinID +
            '"] .lnk-hotspot__pin-icon--inactive',
        );
        const activePin = $(hotspotComponent).find(
          '.lnk-hotspot__pin[data-pin-id="' +
            pinID +
            '"] .lnk-hotspot__pin-icon--active',
        );
        if ($(event.target).hasClass('collapsed')) {
          activePin.removeClass('d-none');
          inactivePin.addClass('d-none');
        } else {
          inactivePin.removeClass('d-none');
          activePin.addClass('d-none');
        }
      });
  }

  /**
   * Activates drag and drop for hotspot pins and will save the data after drop (via post request to magnolia REST).
   */
  enableDragAndDropInEditMode(hotspotComponent) {
    // Check can be removed?
    if (this.urlPost != undefined) {
      $(hotspotComponent)
        .find('.lnk-hotspot__pin .lnk-hotspot__pin-icon')
        .on('dragstart', (event) => {
          this.draggedPin = $(event.target).closest('.lnk-hotspot__pin');
        });

      this.renderedMap.on('dragover', (event) => {
        event.preventDefault();
      });

      this.renderedMap.on('drop', (event) => {
        this.pinDrop(event);
      });
    }
  }

  /**
   * After pin drop this function will be triggered and will save pin offset und styles css.
   * @param {Event} event drop event parameter
   */
  pinDrop(event) {
    const droppedTopOffset = event.offsetY;
    const droppedLeftOffset = event.offsetX;

    // save process
    this.savePinOffset(
      $(this.draggedPin).attr('data-pin-node'),
      this.leftToPercents(droppedLeftOffset),
      this.topToPercents(droppedTopOffset),
    );

    // change css in dragged pin
    $(this.draggedPin).css({
      top: this.topToPercents(droppedTopOffset) + '%',
      left: this.leftToPercents(droppedLeftOffset) + '%',
    });

    // reset
    this.draggedPin = null;
  }

  /**
   * Save the pin value in magnolia JCR after the pin is droped
   * @param {string} nodeName JRC identifier to save value in correct element.
   * @param {number} left Value for position left.
   * @param {number} top Value for position left.
   */
  savePinOffset(nodeName, left, top) {
    const dataObject = {
      properties: [
        {
          name: 'left',
          type: 'String',
          values: [left],
        },
        {
          name: 'top',
          type: 'String',
          values: [top],
        },
      ],
    };
    $.ajax({
      url: this.urlPost + nodeName,
      // url: '/unreachable',
      type: 'POST',
      data: JSON.stringify(dataObject),
      contentType: 'application/json',
      success: () => this.savePinOffsetSuccess(), // CSS CHANGING from line 99
      error: () => this.savePinOffsetError(), // FEHLER MELDUNG ÜBER TOASTS
    });
  }

  savePinOffsetSuccess() {
    generateNotification({
      delay: 2000,
      type: 'success',
      msg: 'Changes successfully saved',
    });
  }
  savePinOffsetError() {
    generateNotification({
      delay: 0,
      type: 'error',
      msg: 'Server error - changes not saved',
    });
  }

  /**
   * === HELPER FUNCTION ===
   */
  resetHotspotIcons(hotspotComponent) {
    $(hotspotComponent)
      .find('.lnk-hotspot__pin-icon--inactive')
      .removeClass('d-none');
    $(hotspotComponent)
      .find('.lnk-hotspot__pin-icon--active')
      .addClass('d-none');
  }

  /**
   * Calculations for top and left value for absolute positioning.
   *
   * Calculation in percantage.
   * Both calculation reduced by the half width/height of the dragged pin,
   * because the pin should place there where the cursor stays at the moment you drop.
   */
  leftToPercents(droppedLeftOffset) {
    return (
      (droppedLeftOffset / $('.lnk-hotspot__map').width()) * 100 -
      ($(this.draggedPin).width() / 2 / $('.lnk-hotspot__map').width()) * 100
    );
  }
  topToPercents(droppedTopOffset) {
    return (
      (droppedTopOffset / $('.lnk-hotspot__map').height()) * 100 -
      ($(this.draggedPin).height() / 2 / $('.lnk-hotspot__map').height()) * 100
    );
  }
}

export default Hotspot;
