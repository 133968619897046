class Lightbox {
  constructor() {
    this.init();
  }

  init() {
    this.disableTextMediaImages();
  }

  disableTextMediaImages() {
    $('.lnk-text-media [data-toggle="modal"]').each(function () {
      $(this).removeAttr('data-toggle');
      $(this).removeAttr('data-target');
    });
  }
}

export default Lightbox;
