import { debounce } from './functions';

class Facts {
  constructor(mainFacts) {
    this.boxHeight = 0;
    this.equalize(mainFacts);

    $(window).on(
      'resize',
      debounce(() => {
        $('.lnk-facts__row').css('height', '');
        this.boxHeight = 0;
        this.equalize(mainFacts);
      }, 250),
    );
  }

  equalize(mainFacts) {
    let that = this;

    mainFacts.find('.lnk-facts__row').each(function () {
      if ($(this).height() > that.boxHeight) {
        that.boxHeight = $(this).height();
      }
    });

    mainFacts.find('.lnk-facts__row').each(function () {
      $(this).height(that.boxHeight);
    });
  }
}

export default Facts;
